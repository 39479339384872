<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
  ----------------------------------------------------------------------------------------
  Item Name: Traffic Management Application
  Author: Hossam Ali
  Author URL: https://www.linkedin.com/in/hossam-ali-7bb41810b/
========================================================================================== -->

<template>
  <vs-prompt
    title="Change status"
    accept-text="Save"
    cancel-text="Cancel"
    button-cancel="border"
    @cancel="removeTodo"
    @accept="submitTodo"
    @close="init"
    :active.sync="activePrompt"
  >
    <div>
      <form>
        <div class="vx-row">
          <div class="vx-col w-full mb-6">
            <label class="text-sm opacity-75">
              <span class="text-primary">*</span>status
            </label>

            <validation-provider name="status" rules="required" v-slot="{}">
              <v-select
                :reduce="(name) => name"
                label="name"
                :options="statuses"
                :clearable="false"
                name="status"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="itemLocal.status"
                class="mb-4 md:mb-0"
                placeholder="Status"
              />
            </validation-provider>
          </div>
        </div>
      </form>
    </div>
  </vs-prompt>
</template>

<script>
import vSelect from "vue-select";
import endpoints from "../../../endpoints";
import axios from "@/axios.js";

export default {
  components: {
    vSelect,
  },
  props: {
    displayPrompt: {
      type: Boolean,
      required: true,
    },
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemLocal: Object.assign({}, this.itemData),
      statuses: ["Active", "Terminated", "Resigned"],
    };
  },
  computed: {
    activePrompt: {
      get() {
        return this.displayPrompt;
      },
      set(value) {
        this.$emit("hideDisplayPrompt", value);
      },
    },
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    removeTodo() {},
    init() {},
    submitTodo() {
      const formData = new FormData();
      formData.append("user_id", this.itemLocal.id);
      formData.append("status", this.itemLocal.status);

      axios
        .post(`${endpoints.API_URL}changeUserStatus`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Employee is Updated",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error",
            text: error.response.data.errors[0],
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
};
</script>
