<!-- =========================================================================================
  Author: Hazem Ashraf
  Author URL: https://www.linkedin.com/in/hazem-ashraf-1242169b/
========================================================================================== -->

<template>
  <div id="data-list">
    <vx-card
      ref="filterCard"
      title="Filters"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer">
        <form ref="form" slot-scope="{ validate }" autocomplete="off">
          <div class="vx-row">
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Company
              </label>
              <validation-provider
                name="company_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  :options="companies"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="items.company_id"
                  class="mb-4"
                  placeholder="Company"
                  :class="{ 'is-valid': !!errors[0] }"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mt-6">
              <div class="vx-row">
                <div class="vx-col md:w-1/2 sm:w-1/1 w-full">
                  <vs-button
                    type="filled"
                    @click="validate().then(fetchItemsData)"
                    class="w-full mb-2"
                    >Filters</vs-button
                  >
                </div>
              </div>
            </div>
        </form>
      </validation-observer>
    </vx-card>
    <vs-popup
      classContent="popup-example"
      title="Confirm Delete"
      :active.sync="popupActive"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider
            name="password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Password"
              v-model="password"
              class="w-full mt-6"
            />
          </ValidationProvider>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="goToDeleteDepartment()"
            class="mb-2 mt-5"
            >Confirm</vs-button
          >
        </form>
      </validation-observer>
    </vs-popup>
    <div class="vx-card p-6 vs-con-loading__container" id="div-loading">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->

        <vs-button
          class="mb-4 md:mb-0"
          v-can:permission="'users_create'"
          @click="goToEmployee()"
        >
          <span style="font-weight: bold; font-size: 13px">+</span> Add Employee
        </vs-button>
        <hr />
        <vs-button
          color="dark"
          class="mb-4 md:mb-0"
          v-can:permission="'users_delete'"
          @click="openConfirm()"
        >
          <span style="font-weight: bold; font-size: 13px">-</span> Delete
          selected
        </vs-button>
      </div>
      <br />

      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <button class="vs-con-dropdown cursor-pointer">
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2">
                    {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  itemsData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : itemsData.length
                }}
                From {{ itemsData.length }}
              </span>
            </div>
          </button>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input
          class="
            sm:mr-4
            mr-0
            sm:w-auto
            w-full
            sm:order-normal
            order-3
            sm:mt-0
            mt-4
          "
          v-model="searchQuery"
          @input="updateSearchQuery"
          placeholder="Search..."
        />
        <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
          >Export CSV</vs-button
        >
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        @row-double-clicked="onRowDoubleClicked"
      ></ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

import axios from "@/axios.js";
// Store Module
import moduleEmployeeManagement from "@/store/get-all-employees/moduleEmployeeManagement.js";
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";

import CellRendererLink from "../../hr-report/main-report/cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "../../hr-report/main-report/cell-renderer/CellRendererStatus.vue";
// Router to navigate
import router from "@/router";
import endpoints from "../../../endpoints";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

import vSelect from "vue-select";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});
export default {
  components: {
    AgGridVue,
    ValidationProvider,
    ValidationObserver,
    // Cell Renderer
    CellRendererActions,
    CellRendererLink,
    CellRendererStatus,
    vSelect,
  },
  data() {
    return {
      page: 1,
      popupActive: false,
      password: null,

      searchQuery: "",

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        resizable: true,
      },
      columnDefs: [
        {
          headerName: "Code",
          field: "Code",
          sortable: true,
          filter: true,
          checkboxSelection: true,
          width: 200,
        },
        {
          headerName: "FullName",
          field: "FullName",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "FirstName",
          field: "FirstName",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "LastName",
          field: "LastName",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "BussniessMobile",
          field: "BussniessMobile",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "PrivateMobile",
          field: "PrivateMobile",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "NationalId",
          field: "NationalId",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "StartDate",
          field: "StartDate",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Company",
          field: "Company",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Category",
          field: "Category",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "JobTitle",
          field: "JobTitle",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Department",
          field: "Department",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Areas",
          field: "areas",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Cities",
          field: "cities",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Image",
          field: "image",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Is Top Manager",
          field: "isTopManager",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Managers",
          field: "managers",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Notes",
          field: "notes",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "Roles",
          field: "roles",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "serial_mobile",
          field: "serial_mobile",
          sortable: true,
          filter: true,
          width: 200,
        },
        {
          headerName: "status",
          field: "status",
          sortable: true,
          filter: true,
          width: 150,
        },
        {
          headerName: "Action",
          field: "transactions",
          filter: false,
          cellRendererFramework: "CellRendererActions"
        },
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,

        CellRendererActions
      },
      items: {
        company_id: null,
      },
    };
  },
  // watch: {
  //   "items.company_id"(obj) {
  //     if (obj) {
  //       this.$vs.loading({
  //         container: "#div-with-loading",
  //         scale: 0.6,
  //       });
  //       axios
  //         .get(`${endpoints.API_URL}company/${obj}/departments`)
  //         .then((response) => {
  //           this.$vs.loading.close("#div-with-loading > .con-vs-loading");
  //           this.departments = response.data.response.data;
  //         });
  //     }
  //   },
  // },
  computed: {
    itemsData() {
      return this.$store.state.employees.items;
    },
    companies() {
      return this.$store.state.company.items;
    },
    // itemsMeta() {
    //   return this.$store.state.employees.meta;
    // },
       paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.page = val;
        this.fetchItemsData(val);
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    // Fetch department Data
    fetchItemsData(val) {
      // if (!this.items.company_id ) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Make sure select the main filters ",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //     color: "danger",
      //   });
      //   return;
      // }
      // if (val) {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6,
      });
    // }
      let params = {
        company_id: this.items.company_id,
        page: val,
      };
      this.$store
        .dispatch("employees/getAlls", params)
        .then(() => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Error",
            text: err,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(500);
        this.company =null;

      // Reset Grid Filter
      // this.gridApi.setFilterModel(null);
      // this.gridApi.onFilterChanged();
      // this.$refs.filterCard.removeRefreshAnimation();
    },
    getCompanies() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6,
      });
      this.$store.dispatch("company/fetchItemsCompanies").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    goToDeleteDepartment() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6,
      });
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      const selectedDataStringPresentation = selectedData.map(
        (node) => node.id
      );
      let obj = {
        ids: selectedDataStringPresentation,
        password: this.password,
      };
      axios
        .delete(`${endpoints.USER_ENDPOINT}/delete`, { data: obj })
        .then((response) => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");
          if (response.status === 200) {
            this.onRemoveSelected();
            this.$vs.notify({
              title: "success",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.popupActive = false;
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
          //      document.location.reload();
        })
        .catch(() => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Error",
            text: "Can't delete this employee",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    openConfirm() {
      this.popupActive = true;
    },
    goToEmployee() {
      router.push("/Add/Employee");
    },

    onRemoveSelected() {
      var selectedData = this.gridApi.getSelectedRows();
      //alert(JSON.stringify(selectedData));
      this.gridApi.applyTransaction({ remove: selectedData });

      //  printResult(res);
    },
    onRowDoubleClicked(event) {
      ////////console.log('row data clicked is: ', JSON.stringify(event.data));

      let permissions = this.$store.state.auth.permissions.length
        ? this.$store.state.auth.permissions
        : localStorage.getItem("permissions");
      if (permissions && permissions.indexOf("users_edit") === -1) {
        return;
      }
      router.push("/Update/Employee?SID=" + event.data.id);
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;
      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }
      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.getCompanies();
    this.fetchItemsData()
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  created() {
    if (!moduleEmployeeManagement.isRegistered) {
      this.$store.registerModule("employees", moduleEmployeeManagement);
      moduleEmployeeManagement.isRegistered = true;
    }
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
  },
};
</script>

<style lang="scss">
#data-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

[dir="rtl"] .ag-theme-material .ag-rtl {
  text-align: right;
}
[dir="rtl"] .ag-rtl {
  direction: rtl;
}
</style>
