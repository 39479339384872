<template>
  <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <!-- <feather-icon
      icon="Edit3Icon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      @click="displayPrompt = true"
    /> -->

    <vs-button
      class="mx-2 px-3"
      color="danger"
      type="line"
      icon-pack="feather"
      icon="icon-flag"
      @click="displayPrompt = true"
    >
      Change status
    </vs-button>

    <todo-edit
      :displayPrompt="displayPrompt"
      :itemData="params.data"
      @hideDisplayPrompt="hidePrompt"
      v-if="displayPrompt"
    ></todo-edit>
  </div>
</template>

<script>
import TodoEdit from "./../UserStatus.vue";

export default {
  name: "CellRendererActions",
  components: {
    TodoEdit,
  },
  data() {
    return {
      displayPrompt: false,
    };
  },

  methods: {
    hidePrompt() {
      this.displayPrompt = false;
    },
  },
};
</script>
